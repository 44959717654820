import React from "react"
import {Link} from "gatsby"
import {FaFacebookF, FaTwitter, FaLinkedinIn, FaArrowRight} from 'react-icons/fa'
import { withPreview } from 'gatsby-source-prismic'

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";

const BlogPost = ({ pageContext, location }) => {
	const { title: { text: title }, content: { html: content }, related = [] } = pageContext;
	return (
		<Layout>
			<SEO title={title} />
			<Container className="py-5">
				<Row className="justify-content-between align-items-center mb-3">
					<Col>
						<Breadcrumb listProps={{ className: "bg-white mb-0 pl-0" }}>
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							<Breadcrumb.Item href="/blog">
								Blog
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{title}</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
					<Col xs={12} md={2} className="d-flex justify-content-around">
						<Link to={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}>
							<FaFacebookF />
						</Link>
						<Link to={`https://twitter.com/intent/tweet?url=${location.href}&text=`}>
							<FaTwitter />
						</Link>
						<Link to={`https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=&summary=&source=`}>
							<FaLinkedinIn />
						</Link>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1 className="mb-5">{title}</h1>
						<div dangerouslySetInnerHTML={{ __html: content}} />
					</Col>
				</Row>
				<Row className="mt-5">
					<Col xs={12}>
						<h2 className="mb-3">Related content</h2>
					</Col>
					{related.map(doc => doc.article.document && (
						<Col xs="12" md="4">
							<Card>
								<Card.Img variant="top" src={doc.article.document.data.preview_img.url} />
								<Card.Body>
									<Card.Title>{doc.article.document.data.title.text}</Card.Title>
									<div className="text-right">
										<Link to={`/${doc.article.uid}`}>Read more <FaArrowRight /></Link>
									</div>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
				<div className="text-center mt-5">
					<Link to="/">Go back to the homepage</Link>
				</div>
			</Container>
		</Layout>
	);
}

export default withPreview(BlogPost);
